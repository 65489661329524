import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      fields: [
        { 
          key: 'doc_date', 
          label: 'วันที่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_ref', 
          label: 'เอกสารอ้างอิง', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'inv_no', 
          label: 'เลขที่ใบกำกับภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'receipt_no', 
          label: 'เลขที่ใบเสร็จ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'list', 
          label: 'รายการ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'revenue', 
          label: 'รายรับ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
        { 
          key: 'expenses', 
          label: 'รายจ่าย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
        { 
          key: 'other_expenses', 
          label: 'ค่าใช้จ่ายอื่น ๆ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
        { 
          key: 'note', 
          label: 'หมายเหตุ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        }
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'WD-201665156',
          doc_date: new Date(),
          list: 'ซื้อสินค้า',
          code: 'ST-001',
          inv_no: 'INV-202215615',
          revenue: 0,
          expenses: 2000,
          other_expenses: 0,
          note: 'วันที่ได้รับชำระ หรือจ่ายชำระ'
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
